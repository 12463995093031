<template>
  <div class="row justify-content-center">
    <div class="col-12">
      <div class="card card-custom">
        <div class="card-body p-0">
          <!--begin: Wizard-->
          <div
            class="wizard wizard-1"
            id="kt_wizard_v1"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
          >
            <!--begin: Wizard Body-->
            <div
              class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10"
            >
              <div class="col-xl-12">
                <!--begin: Wizard Form-->

                <!--begin: Wizard Step 1-->
                <div
                  class="pb-5"
                  data-wizard-type="step-content"
                  data-wizard-state="current"
                >
                  <h5
                    class="font-weight-bolder text-dark font-size-h6 font-size-h3-lg"
                  >
                    Detail Stok Bahan
                  </h5>
                  <table class="table mt-8">
                    <tr>
                      <th class="pr-1">
                        <span>Tanggal</span>
                      </th>
                      <th class="pl-0 pr-0">
                        <span>:</span>
                      </th>
                      <td class="pl-2">
                        <span>{{ detail.date_display }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th class="pr-1">
                        <span>Bahan</span>
                      </th>
                      <th class="pl-0 pr-0">
                        <span>:</span>
                      </th>
                      <td class="pl-2">
                        <span>{{ detail.lab_material_name }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th class="pr-1">
                        <span>Jenis Stok</span>
                      </th>
                      <th class="pl-0 pr-0">
                        <span>:</span>
                      </th>
                      <td class="pl-2">
                        <span>{{ detail.stock_in_out_display }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th class="pr-1">
                        <span>Jumlah</span>
                      </th>
                      <th class="pl-0 pr-0">
                        <span>:</span>
                      </th>
                      <td class="pl-2">
                        <span>{{ detail.quantity }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th class="pr-1">
                        <span>Sisa Stok</span>
                      </th>
                      <th class="pl-0 pr-0">
                        <span>:</span>
                      </th>
                      <td class="pl-2">
                        <span>{{ detail.remaining_stock }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th class="pr-1">
                        <span>Keterangan</span>
                      </th>
                      <th class="pl-0 pr-0">
                        <span>:</span>
                      </th>
                      <td class="pl-2">
                        <span>{{ detail.description }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th class="pr-1">
                        <span>Penginput</span>
                      </th>
                      <th class="pl-0 pr-0">
                        <span>:</span>
                      </th>
                      <td class="pl-2">
                        <span>{{ detail.user_name }}</span>
                      </td>
                    </tr>
                  </table>
                </div>
                <!--end: Wizard Step 1-->

                <!--begin: Wizard Actions -->
                <div class="d-flex justify-content-between border-top pt-10">
                  <div>
                    <b-button
                      type="button"
                      class="ml-2"
                      variant="primary"
                      @click="$router.push('/lab-material-stocks')"
                    >
                      Tutup
                    </b-button>
                    <!-- <b-button
                      type="button"
                      variant="outline-primary"
                      class="ml-2 btn-hover-secondary"
                      @click="$router.push('/lab-material-stocks/edit/' + $route.params.id)"
                      v-if="btnAccess"
                    >
                      Edit
                    </b-button> -->
                  </div>
                </div>
                <!--end: Wizard Actions -->
                <!--end: Wizard Form-->
              </div>
            </div>
            <!--end: Wizard Body-->
          </div>
        </div>
        <!--end: Wizard-->
      </div>
    </div>
  </div>
</template>

<style>
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import module from "@/core/modules/CrudModule.js";

export default {
  data() {
    return {
      detail: {},
      // access
      btnAccess: true
    };
  },
  methods: {
    async get() {
      this.detail = await module.get('api/lab-material-stocks/' + this.$route.params.id)
      // If Data Not Found
      if (this.detail == null) {
        // Redirect To List
        this.$router.push('/lab-material-stocks')
      }
    },

    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "8016") {
          this.btnAccess = true
        }
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Stok Bahan", route: "/lab-material-stocks" },
      { title: "Detail" },
    ]);

    this.get();
    this.checkAccess();
  },
};
</script>
